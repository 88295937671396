import Image from 'next/image'
import Link from 'next/link'

import Auth from '@/layout/Auth'
import Button from '@/shared/button/Button'
import Input from '@/shared/input'

import { baseUrl, getCookie } from '@/utils/session'
import { useForm } from 'react-hook-form'

import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { loginUser } from '@/redux/slices/auth/loginUser'

import { useRouter } from 'next/router'
import { useEffect } from 'react'

const SignIn = () => {
  const router = useRouter()

  const dispatch = useAppDispatch()
  const { signInStatus } = useAppSelector(({ login }) => login)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })

  const onSubmit = handleSubmit(data =>
    dispatch(
      loginUser({
        email: data.email,
        password: data.password,
      })
    )
  )

  const { userData } = useAppSelector(({ updateUser }) => updateUser)
  const { isUser } = useAppSelector(({ checkUser }) => checkUser)

  useEffect(() => {
    const token = getCookie('access_token', { req: {} })

    if (isUser && token && token !== 'undefined') {
      if (router.query.redirect) {
        const intendedRoute: any = router.query.redirect
        router.push(intendedRoute)
      } else {
        if (userData.role === 'client') {
          router.replace('/explore')
        } else if (userData.role === 'pro') {
          router.replace('/schedule')
        }
      }
    }
    return () => {}
  }, [isUser, router, userData])

  const addRedirectUrl = (uri: string): string => {
    if (!router.query.redirect) {
      return uri
    }

    const redirectPath = encodeURIComponent(router.query.redirect as string)
    return `${uri}?redirect=${redirectPath}`
  }

  return (
    <Auth page="Sign In">
      <section>
        <header>
          <h1>Welcome back. Sign in.</h1>
          <a href={addRedirectUrl(`${baseUrl}/oauth/google`)}>
            <span>
              <Image src="/google.svg" width="24" height="24" alt="Google" />
              Google
            </span>
          </a>

          <a href={addRedirectUrl(`${baseUrl}/oauth/linkedin`)}>
            <span>
              <Image src="/linkedin.svg" width="24" height="24" alt="linkedin" /> LinkedIn
            </span>
          </a>

          <p>
            <span></span>or <span></span>
          </p>
        </header>

        <form onSubmit={onSubmit}>
          <Input
            label="Email address"
            type="email"
            name="email"
            placeholder="Email address"
            register={register}
            errors={errors}
            validationSchema={{
              required: 'Email address is required',
            }}
          />

          <Input
            label="Password"
            type="password"
            name="password"
            placeholder="Password"
            register={register}
            errors={errors}
            validationSchema={{
              required: 'Password is required',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters',
              },
            }}
          />

          <Link href="/forgot-password">Forgot Password?</Link>
          <Button usage="submit" type="primary" loading={signInStatus === 'pending'}>
            Sign in{' '}
          </Button>
          <aside>
            New here? <Link href="/signup">Create account</Link>
          </aside>
        </form>
      </section>
    </Auth>
  )
}

export default SignIn
