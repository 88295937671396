import { Fragment } from 'react'
import Head from 'next/head'

import S from './auth.module.scss'
import Image from 'next/image'
import authImage from '@/public/auth.svg'
import { userRedirect } from '@/hooks'

const Auth = ({ children, page }: { children: any; page: string }) => {
  userRedirect()
  return (
    <Fragment>
      <Head>
        <title>{page ? `Pickt. | ${page}` : 'Pickt.'}</title>
      </Head>
      <main className={S.layout}>
        <section className={S.layout_left}>
          <header>
            <Image src="/logowhite.svg" width={210} height={60} alt={'pickt'} />
            <p>meet, talk, know, thrive!</p>
          </header>

          <div>
            <Image src={authImage} alt="" />
          </div>
        </section>
        <section className={S.layout_right}>
          <div className={S.layoutMb_head}>
            <header>
              <Image
                className="logotext"
                src="/logotext.svg"
                width={120}
                height={32}
                alt={'pickt'}
              />
            </header>
            <Image src={'/authMb.svg'} width="80" height="80" alt="" />
          </div>
          {children}
        </section>
      </main>
    </Fragment>
  )
}

export default Auth
